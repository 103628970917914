const axios = require("axios")

const fetchAPI = async (query, { variables } = {}) => {
  try {
    const res = await axios(process.env.GATSBY_GRAPHQL_URL, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "X-Shopify-Storefront-Access-Token": process.env.GATSBY_ACCESS_TOKEN,
        Accept: "application/json",
      },
      data: JSON.stringify({
        query,
        variables,
      }),
    })
    return res
  } catch (error) {
    throw new Error("Failed to fetch API")
  }
}

export const createShopifyCustomer = async ({ email, password }) => {
  var result = await fetchAPI(
    `mutation customerCreate($input: CustomerCreateInput!) {
    customerCreate(input: $input) {
      customer {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }`,
    {
      variables: {
        input: { email, password },
      },
    }
  )
  if (result?.data?.data?.customerCreate?.customer) {
    return {
      status: "success",
      data: result?.data?.data?.customerCreate.customer,
    }
  }

  if (result?.data?.data?.customerCreate?.customerUserErrors) {
    if (
      result?.data?.data?.customerCreate.customerUserErrors[0].code === "TAKEN"
    ) {
      return {
        status: "error",
        message: "Pro zadaný e-mail již existuje účet.",
      }
    }
  }

  return { status: "error", message: "Něco se pokazilo, zkuste to později." }
}

export const updateCheckoutNote = async (checkoutId, note) => {
  var data = await fetchAPI(
    `mutation checkoutAttributesUpdateV2($checkoutId: ID!, $input: CheckoutAttributesUpdateV2Input!) {
      checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: $input) {
        checkout {
          note
        }
        checkoutUserErrors {
          code
          field
          message
        }
      }
    }`,
    {
      variables: {
        checkoutId,
        input: {
          note,
        },
      },
    }
  )

  return data
}

export const logInCustomer = async ({ email, password }) => {
  var data = await fetchAPI(
    `mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
      customerAccessTokenCreate(input: $input) {
        customerAccessToken {
          accessToken
          expiresAt
        }
        customerUserErrors {
          code
          field
          message
        }
      }
    }`,
    {
      variables: {
        input: { email, password },
      },
    }
  )

  if (!data.data.data.customerAccessTokenCreate.customerAccessToken) {
    // const error = data.data.data.customerAccessTokenCreate.customerUserErrors
    return { data: "Neplatné přihlašovací údaje", valid: false }
  }

  return {
    data: data.data.data.customerAccessTokenCreate.customerAccessToken
      .accessToken,
    valid: true,
  }
}

export const resetPassword = async ({ email }) => {
  var result = await fetchAPI(
    `mutation customerRecover($email: String!) {
      customerRecover(email: $email) {
        customerUserErrors {
          code
          field
          message
        }
      }
    }`,
    {
      variables: {
        email,
      },
    }
  )

  if (result.data.data.customerRecover?.customerUserErrors?.length) {
    if (
      result.data.data.customerRecover?.customerUserErrors[0].code ===
      "UNIDENTIFIED_CUSTOMER"
    ) {
      return {
        data: "Neexistuje žádný účet registrovaný k této e-mailové adrese",
        valid: false,
      }
    }
    return { data: "Nastala chyba, zkuste to prosím později.", valid: false }
  }

  if (result.data.errors && result.data.errors.length) {
    return { data: "Nastala chyba, zkuste to prosím později.", valid: false }
  }

  return {
    data: result.data.data.customerRecover,
    valid: true,
  }
}

export const getCustomerInfo = async accessToken => {
  var data = await fetchAPI(
    `query getCustomer($accessToken: String!) {
      customer(customerAccessToken: $accessToken) {
        email
        createdAt
        addresses (first:1){
          edges{
            node{
              id
              address1
              address2
              city
              company
              country
              firstName
              lastName
              name
              phone
              zip
            }
          }
        }
        orders(first: 5) {
          edges {
            node {
              id
              name
              customerUrl
              orderNumber
              processedAt
              subtotalPrice
              totalTaxV2{
                amount
              }
              totalShippingPriceV2{
                amount
              }
              totalPrice
              fulfillmentStatus
              financialStatus
              lineItems(first: 20) {
                edges {
                  node {
                    quantity
                    title
                    variant{
                      image{
                        src
                        altText
                      }
                      price
                    }
                  }
                }
              }
              shippingAddress{
                formatted(withName: true)
              }
            }
          }
        }
      }
    }`,
    {
      variables: {
        accessToken,
      },
    }
  )

  return data.data.data.customer
}

export const createCustomerAddress = async ({ address, accessToken }) => {
  var data = await fetchAPI(
    `mutation customerAddressCreate($accessToken: String!, $address: MailingAddressInput!) {
        customerAddressCreate(customerAccessToken: $accessToken, address: $address) {
          customerAddress {
            id
          }
          customerUserErrors {
            code
            field
            message
          }
        }
    }`,
    {
      variables: {
        address,
        accessToken,
      },
    }
  )

  return data
}

export const updateCustomerAddress = async ({ address, accessToken, id }) => {
  var data = await fetchAPI(
    `mutation customerAddressUpdate($accessToken: String!, $id: ID!, $address: MailingAddressInput!) {
      customerAddressUpdate(customerAccessToken: $accessToken, id: $id, address: $address) {
        customerAddress {
          id
        }
        customerUserErrors {
          code
          field
          message
        }
      }
    }
    
    `,
    {
      variables: {
        address,
        accessToken,
        id,
      },
    }
  )

  return data
}

export const getArticles = async () => {
  var data = await fetchAPI(
    `{articles(first: 10) {
      edges {
        node {
          handle
          content
          title
        }
      }
    }}
    
    `
  )

  return data
}

export const getPage = async handle => {
  const { data } = await fetchAPI(
    `query getPage ($handle: String!){
      pageByHandle(handle: $handle) {
          handle
          body
    }
  }
    
    `,
    {
      variables: {
        handle,
      },
    }
  )

  return data.data.pageByHandle
}

export const getProductQuantity = async (handle, id) => {
  const { data } = await fetchAPI(
    `query getAvailableQuantity ($handle: String!){
      productByHandle(handle: $handle) {
        variants(first: 10, sortKey: TITLE) {
          edges {
            node {
              id
              title
              quantityAvailable
            }
          }
        }
    }
  }
    
    `,
    {
      variables: {
        handle,
      },
    }
  )

  const result = data.data.productByHandle?.variants?.edges.filter(
    ({ node }) => node.id === id
  )[0]
  return result?.node?.quantityAvailable
}
