import React from "react"
import { CookiesProvider } from "react-cookie"
import { MediaContextProvider } from "./src/utils/Media"
// import { ProductContextProvider } from "./src/context/ProductContext"
import { CartContextProvider } from "./src/context/CartContext"
import { AgeContextProvider } from "./src/context/AgeContext"
import SimpleReactLightbox from "simple-react-lightbox"

const onRouteUpdate = ({ location }) => {
  if (typeof window.gtag !== "undefined") {
    window.gtag("event", "page_view", {
      page_location: location.pathname,
    })
  }
}

const wrapRootElement = ({ element }) => (
  <SimpleReactLightbox>
    <MediaContextProvider>
      <AgeContextProvider>
        <CookiesProvider>
          <CartContextProvider>
            {/* <ProductContextProvider> */}
            {element}
            {/* </ProductContextProvider>*/}
          </CartContextProvider>
        </CookiesProvider>
      </AgeContextProvider>
    </MediaContextProvider>
  </SimpleReactLightbox>
)

export { wrapRootElement, onRouteUpdate }
