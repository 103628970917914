exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-kontakty-js": () => import("./../../../src/pages/kontakty.js" /* webpackChunkName: "component---src-pages-kontakty-js" */),
  "component---src-pages-kosik-js": () => import("./../../../src/pages/kosik.js" /* webpackChunkName: "component---src-pages-kosik-js" */),
  "component---src-pages-muj-ucet-js": () => import("./../../../src/pages/muj-ucet.js" /* webpackChunkName: "component---src-pages-muj-ucet-js" */),
  "component---src-pages-pro-zakazniky-js": () => import("./../../../src/pages/pro-zakazniky.js" /* webpackChunkName: "component---src-pages-pro-zakazniky-js" */),
  "component---src-pages-produkty-js": () => import("./../../../src/pages/produkty.js" /* webpackChunkName: "component---src-pages-produkty-js" */),
  "component---src-pages-registrace-js": () => import("./../../../src/pages/registrace.js" /* webpackChunkName: "component---src-pages-registrace-js" */),
  "component---src-pages-vse-o-nakupu-js": () => import("./../../../src/pages/vse-o-nakupu.js" /* webpackChunkName: "component---src-pages-vse-o-nakupu-js" */),
  "component---src-pages-zapomenute-heslo-js": () => import("./../../../src/pages/zapomenute-heslo.js" /* webpackChunkName: "component---src-pages-zapomenute-heslo-js" */),
  "component---src-templates-article-template-index-js": () => import("./../../../src/templates/ArticleTemplate/index.js" /* webpackChunkName: "component---src-templates-article-template-index-js" */),
  "component---src-templates-pages-template-index-js": () => import("./../../../src/templates/PagesTemplate/index.js" /* webpackChunkName: "component---src-templates-pages-template-index-js" */),
  "component---src-templates-product-template-index-js": () => import("./../../../src/templates/ProductTemplate/index.js" /* webpackChunkName: "component---src-templates-product-template-index-js" */),
  "component---src-templates-products-template-index-js": () => import("./../../../src/templates/ProductsTemplate/index.js" /* webpackChunkName: "component---src-templates-products-template-index-js" */),
  "component---src-templates-search-template-index-js": () => import("./../../../src/templates/SearchTemplate/index.js" /* webpackChunkName: "component---src-templates-search-template-index-js" */),
  "component---src-templates-subcategories-template-index-js": () => import("./../../../src/templates/SubcategoriesTemplate/index.js" /* webpackChunkName: "component---src-templates-subcategories-template-index-js" */)
}

