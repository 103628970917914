import React, { useState, useEffect, createContext } from "react"
import Client from "shopify-buy"
import { updateCheckoutNote } from "../lib/api"

const defaultState = {
  cart: {},
}

const CartContext = createContext(defaultState)
export default CartContext

// Initializing a client to return content in the store's primary language
let client = Client.buildClient({
  domain: `${process.env.GATSBY_SHOP_NAME}.myshopify.com`,
  storefrontAccessToken: process.env.GATSBY_ACCESS_TOKEN,
})

export const CartContextProvider = ({ children }) => {
  const [checkout, setCheckout] = useState(
    JSON.parse(
      typeof window !== "undefined" ? localStorage.getItem("checkout") : null
    )
  )
  const [successfulOrder, setSuccessfulOrder] = useState(null)
  const checkoutId = checkout?.id

  const buildClientWithLocale = locale => {
    client = Client.buildClient({
      domain: `${process.env.GATSBY_SHOP_NAME}.myshopify.com`,
      storefrontAccessToken: process.env.GATSBY_ACCESS_TOKEN,
      language: locale === "en" ? "en-EN" : "cs-CS",
    })
  }

  useEffect(() => {
    const getCheckout = async () => {
      if (checkoutId && typeof window !== "undefined") {
        const fetchedCheckout = await client.checkout.fetch(checkoutId)

        if (fetchedCheckout?.completedAt) {
          localStorage.removeItem("checkout")
          setCheckout(null)
          setSuccessfulOrder(fetchedCheckout)
        } else {
          setCheckout(fetchedCheckout)
          localStorage.setItem("checkout", JSON.stringify(fetchedCheckout))
        }
      }
    }

    getCheckout()
  }, [setCheckout, setSuccessfulOrder, checkoutId])

  async function updateCartNote(note) {
    // if no checkout id, create a new checkout
    let newCheckout = checkout || (await client.checkout.create())

    await updateCheckoutNote(newCheckout.id, note)
    const fetchedCheckout = await client.checkout.fetch(checkoutId)

    setCheckout(fetchedCheckout)
    setSuccessfulOrder(null)
    if (typeof window !== "undefined") {
      localStorage.setItem("checkout", JSON.stringify(fetchedCheckout))
    }
  }

  async function getProductById(productId) {
    const product = await client.product.fetch(productId)
    return product
  }

  const updateLineItem = async ({ variantId, quantity, customAttributes }) => {
    // if no checkout id, create a new checkout
    let newCheckout = checkout || (await client.checkout.create())

    // check to see if this variantId exists in storedCheckout
    const lineItemVariant = newCheckout.lineItems?.find(
      lineItem => lineItem.variant.id === variantId
    )

    if (lineItemVariant) {
      const newQuantity = lineItemVariant.quantity + quantity
      if (newQuantity) {
        newCheckout = await client.checkout.updateLineItems(newCheckout.id, [
          {
            id: lineItemVariant.id,
            quantity: newQuantity,
          },
        ])
      } else {
        newCheckout = await client.checkout.removeLineItems(newCheckout.id, [
          lineItemVariant.id,
        ])
      }
    } else {
      newCheckout = await client.checkout.addLineItems(newCheckout.id, [
        {
          variantId: variantId.replace("Shopify__ProductVariant__", ""),
          quantity,
          customAttributes,
        },
      ])
    }

    // Tady se hodil error
    setCheckout(newCheckout)

    setSuccessfulOrder(null)
    if (typeof window !== "undefined") {
      localStorage.setItem("checkout", JSON.stringify(newCheckout))
    }
  }

  const removeLineItem = async lineItemId => {
    const newCheckout = await client.checkout.removeLineItems(checkout.id, [
      lineItemId,
    ])

    setCheckout(newCheckout)
  }

  const dismissSuccessfulOrder = () => {
    setSuccessfulOrder(null)
  }

  return (
    <CartContext.Provider
      value={{
        buildClientWithLocale,
        checkout,
        updateLineItem,
        removeLineItem,
        getProductById,
        successfulOrder,
        dismissSuccessfulOrder,
        updateCartNote,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}
