import React, { useState, createContext } from "react"

const AgeContext = createContext()
export default AgeContext

export function AgeContextProvider({ children }) {
  const [showModal, setShowModal] = useState(true)

  return (
    <AgeContext.Provider
      value={{
        showModal,
        setShowModal,
      }}
    >
      {children}
    </AgeContext.Provider>
  )
}
